import { trimEnd } from 'lodash';

export default async function({ route, store, redirect }) {
    /** @type {object[]} redirects. */
    const redirects = await store.dispatch('redirects/getAll'),
        /** @type {string} routePath */
        routePath = trimEnd(route.fullPath, '/'),
        /** @type {object} foundRedirect */
        foundRedirect = redirects.find((currentRedirect) => {
            if (currentRedirect.uri === routePath) {
                return true;
            }

            if (new RegExp(`^${currentRedirect.uri}$`, 'i').test(routePath)) {
                return true;
            }

            return false;
        });

    if (foundRedirect) {
        redirectTo(routePath, foundRedirect, redirect);
    }
}

export function redirectTo(route, foundRedirect, redirect) {
    let path = foundRedirect.to;

    // if we find regex $ replace tags in the redirect, use regex to replace the uri.
    if (/\$/i.test(path)) {
        path = route.replace(new RegExp(`^${foundRedirect.uri}$`, 'i'), foundRedirect.to);
    }

    redirect(foundRedirect.type, path);
}
