const middleware = {}

middleware['activeSite'] = require('../middleware/activeSite.js')
middleware['activeSite'] = middleware['activeSite'].default || middleware['activeSite']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['mijnOmgevingEnabled'] = require('../middleware/mijnOmgevingEnabled.js')
middleware['mijnOmgevingEnabled'] = middleware['mijnOmgevingEnabled'].default || middleware['mijnOmgevingEnabled']

middleware['quickscanEnabled'] = require('../middleware/quickscanEnabled.js')
middleware['quickscanEnabled'] = middleware['quickscanEnabled'].default || middleware['quickscanEnabled']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
