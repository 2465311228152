import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08581dbc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0b63517a = () => interopDefault(import('../pages/mijn/index.vue' /* webpackChunkName: "pages/mijn/index" */))
const _2894013d = () => interopDefault(import('../pages/quickscan/index.vue' /* webpackChunkName: "pages/quickscan/index" */))
const _1b277184 = () => interopDefault(import('../styleguide/pages/base/index.vue' /* webpackChunkName: "" */))
const _cf00ec0a = () => interopDefault(import('../pages/zoek.vue' /* webpackChunkName: "pages/zoek" */))
const _a2d53966 = () => interopDefault(import('../pages/mijn/aanvragen/index.vue' /* webpackChunkName: "pages/mijn/aanvragen/index" */))
const _7ad27e44 = () => interopDefault(import('../pages/mijn/account.vue' /* webpackChunkName: "pages/mijn/account" */))
const _3025033a = () => interopDefault(import('../pages/mijn/documenten.vue' /* webpackChunkName: "pages/mijn/documenten" */))
const _77644560 = () => interopDefault(import('../pages/mijn/help.vue' /* webpackChunkName: "pages/mijn/help" */))
const _8a605546 = () => interopDefault(import('../pages/mijn/login/index.vue' /* webpackChunkName: "pages/mijn/login/index" */))
const _617a4dbb = () => interopDefault(import('../pages/mijn/organisatie.vue' /* webpackChunkName: "pages/mijn/organisatie" */))
const _43423bc3 = () => interopDefault(import('../styleguide/pages/components/index.vue' /* webpackChunkName: "" */))
const _2481b971 = () => interopDefault(import('../styleguide/pages/form/index.vue' /* webpackChunkName: "" */))
const _783731ad = () => interopDefault(import('../styleguide/pages/media/index.vue' /* webpackChunkName: "" */))
const _7c2b229c = () => interopDefault(import('../styleguide/pages/typography/index.vue' /* webpackChunkName: "" */))
const _68b12078 = () => interopDefault(import('../pages/mijn/login/account.vue' /* webpackChunkName: "pages/mijn/login/account" */))
const _586be680 = () => interopDefault(import('../pages/mijn/reset/request.vue' /* webpackChunkName: "pages/mijn/reset/request" */))
const _b3622dd8 = () => interopDefault(import('../pages/mijn/login/verifieren/_.vue' /* webpackChunkName: "pages/mijn/login/verifieren/_" */))
const _228c1dd0 = () => interopDefault(import('../pages/mijn/reset/reset/_.vue' /* webpackChunkName: "pages/mijn/reset/reset/_" */))
const _79d23f82 = () => interopDefault(import('../pages/mijn/aanvragen/_webrequestcode.vue' /* webpackChunkName: "pages/mijn/aanvragen/_webrequestcode" */))
const _25784aae = () => interopDefault(import('../pages/jaarverslagen/_slug.vue' /* webpackChunkName: "pages/jaarverslagen/_slug" */))
const _411e9322 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _7abd19d3 = () => interopDefault(import('../pages/portfolio/_slug.vue' /* webpackChunkName: "pages/portfolio/_slug" */))
const _5222ece6 = () => interopDefault(import('../pages/projecten/_slug.vue' /* webpackChunkName: "pages/projecten/_slug" */))
const _8101aeb2 = () => interopDefault(import('../pages/wat-we-doen/_program/index.vue' /* webpackChunkName: "pages/wat-we-doen/_program/index" */))
const _6643caa2 = () => interopDefault(import('../pages/wat-we-doen/_program/_slug/index.vue' /* webpackChunkName: "pages/wat-we-doen/_program/_slug/index" */))
const _0abc562f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _08581dbc,
    name: "index___en"
  }, {
    path: "/mijn",
    component: _0b63517a,
    name: "mijn___nl"
  }, {
    path: "/quickscan",
    component: _2894013d,
    name: "quickscan___nl"
  }, {
    path: "/styleguide",
    component: _1b277184
  }, {
    path: "/zoek",
    component: _cf00ec0a,
    name: "zoek___nl"
  }, {
    path: "/en/my",
    component: _0b63517a,
    name: "mijn___en"
  }, {
    path: "/en/quickscan",
    component: _2894013d,
    name: "quickscan___en"
  }, {
    path: "/en/search",
    component: _cf00ec0a,
    name: "zoek___en"
  }, {
    path: "/en/styleguide",
    component: _1b277184
  }, {
    path: "/mijn/aanvragen",
    component: _a2d53966,
    name: "mijn-aanvragen___nl"
  }, {
    path: "/mijn/account",
    component: _7ad27e44,
    name: "mijn-account___nl"
  }, {
    path: "/mijn/documenten",
    component: _3025033a,
    name: "mijn-documenten___nl"
  }, {
    path: "/mijn/help",
    component: _77644560,
    name: "mijn-help___nl"
  }, {
    path: "/mijn/login",
    component: _8a605546,
    name: "mijn-login___nl"
  }, {
    path: "/mijn/organisatie",
    component: _617a4dbb,
    name: "mijn-organisatie___nl"
  }, {
    path: "/styleguide/components",
    component: _43423bc3
  }, {
    path: "/styleguide/form",
    component: _2481b971
  }, {
    path: "/styleguide/media",
    component: _783731ad
  }, {
    path: "/styleguide/typography",
    component: _7c2b229c
  }, {
    path: "/en/my/account",
    component: _7ad27e44,
    name: "mijn-account___en"
  }, {
    path: "/en/my/documents",
    component: _3025033a,
    name: "mijn-documenten___en"
  }, {
    path: "/en/my/help",
    component: _77644560,
    name: "mijn-help___en"
  }, {
    path: "/en/my/login",
    component: _8a605546,
    name: "mijn-login___en"
  }, {
    path: "/en/my/organisation",
    component: _617a4dbb,
    name: "mijn-organisatie___en"
  }, {
    path: "/en/my/requests",
    component: _a2d53966,
    name: "mijn-aanvragen___en"
  }, {
    path: "/en/styleguide/components",
    component: _43423bc3
  }, {
    path: "/en/styleguide/form",
    component: _2481b971
  }, {
    path: "/en/styleguide/media",
    component: _783731ad
  }, {
    path: "/en/styleguide/typography",
    component: _7c2b229c
  }, {
    path: "/mijn/login/account",
    component: _68b12078,
    name: "mijn-login-account___nl"
  }, {
    path: "/mijn/reset/request",
    component: _586be680,
    name: "mijn-reset-request___nl"
  }, {
    path: "/en/my/login/account",
    component: _68b12078,
    name: "mijn-login-account___en"
  }, {
    path: "/en/my/reset/request",
    component: _586be680,
    name: "mijn-reset-request___en"
  }, {
    path: "/",
    component: _08581dbc,
    name: "index___nl"
  }, {
    path: "/en/my/login/verify/:token?",
    component: _b3622dd8,
    name: "mijn-login-verifieren-all___en"
  }, {
    path: "/en/my/reset/reset/*",
    component: _228c1dd0,
    name: "mijn-reset-reset-all___en"
  }, {
    path: "/en/my/requests/:webrequestcode",
    component: _79d23f82,
    name: "mijn-aanvragen-webrequestcode___en"
  }, {
    path: "/mijn/login/verifieren/:token?",
    component: _b3622dd8,
    name: "mijn-login-verifieren-all___nl"
  }, {
    path: "/mijn/reset/reset/*",
    component: _228c1dd0,
    name: "mijn-reset-reset-all___nl"
  }, {
    path: "/en/annual-reports/:slug?",
    component: _25784aae,
    name: "jaarverslagen-slug___en"
  }, {
    path: "/en/news/:slug?",
    component: _411e9322,
    name: "nieuws-slug___en"
  }, {
    path: "/en/portfolio/:slug?",
    component: _7abd19d3,
    name: "portfolio-slug___en"
  }, {
    path: "/en/projects/:slug?",
    component: _5222ece6,
    name: "projecten-slug___en"
  }, {
    path: "/en/what-we-do/:program",
    component: _8101aeb2,
    name: "wat-we-doen-program___en"
  }, {
    path: "/mijn/aanvragen/:webrequestcode",
    component: _79d23f82,
    name: "mijn-aanvragen-webrequestcode___nl"
  }, {
    path: "/en/what-we-do/:program?/:slug",
    component: _6643caa2,
    name: "wat-we-doen-program-slug___en"
  }, {
    path: "/jaarverslagen/:slug?",
    component: _25784aae,
    name: "jaarverslagen-slug___nl"
  }, {
    path: "/nieuws/:slug?",
    component: _411e9322,
    name: "nieuws-slug___nl"
  }, {
    path: "/portfolio/:slug?",
    component: _7abd19d3,
    name: "portfolio-slug___nl"
  }, {
    path: "/projecten/:slug?",
    component: _5222ece6,
    name: "projecten-slug___nl"
  }, {
    path: "/wat-we-doen/:program",
    component: _8101aeb2,
    name: "wat-we-doen-program___nl"
  }, {
    path: "/wat-we-doen/:program?/:slug",
    component: _6643caa2,
    name: "wat-we-doen-program-slug___nl"
  }, {
    path: "/en/*",
    component: _0abc562f,
    name: "all___en"
  }, {
    path: "/*",
    component: _0abc562f,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
