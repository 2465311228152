<template>
    <div class="navigation" :class="navigationClasses" @clickaway="closeMenu">
        <div class="navigation__bar">
            <div class="navigation__bar__background" />
            <div class="navigation__bar__content">
                <div class="navigation__bar__logo">
                    <nuxt-link :to="localePath({ name: 'index' })" class="link link--image-block" @click.native="closeMenu"><navigation-logo /></nuxt-link>
                </div>
                <div class="navigation__bar__links">
                    <template v-if="isDoen || isBglf">
                        <template v-if="applyLink.startsWith('https://aanvraag.doen.nl')">
                            <a :href="applyLink" class="navigation__bar__links__button button button--tertiary">
                                <template v-if="isDoen">{{ $t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ $t('navigation.links.application') }}</template>
                            </a>
                            <a :href="applyLink" class="navigation__bar__links__apply">
                                <template v-if="isDoen">{{ $t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ $t('navigation.links.application') }}</template>
                            </a>
                            <a :href="applyLink" class="navigation__bar__links__account"><icon><icon-user /></icon></a>
                        </template>
                        <template v-else>
                            <nuxt-link :to="localePath(applyLink)" class="navigation__bar__links__button button button--tertiary" @click.native="closeMenu">
                                <template v-if="isDoen">{{ $t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ $t('navigation.links.application') }}</template>
                            </nuxt-link>
                            <nuxt-link :to="localePath(applyLink)" class="navigation__bar__links__apply" @click.native="closeMenu">
                                <template v-if="isDoen">{{ $t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ $t('navigation.links.application') }}</template>
                            </nuxt-link>

                            <a v-if="isDoen" href="https://aanvraag.doen.nl" class="navigation__bar__links__account"><icon><icon-user /></icon></a>
                            <nuxt-link v-else :to="localePath({ name: 'mijn' })" class="navigation__bar__links__account" @click.native="closeMenu"><icon><icon-user /></icon></nuxt-link>
                        </template>
                    </template>

                    <nuxt-link :to="localePath({ name: 'zoek' })" class="navigation__bar__links__search" @click.native="closeMenu"><icon><icon-search /></icon></nuxt-link>

                    <div class="navigation__bar__hamburger" @click="toggleMenu">
                        <hamburger />
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation__menu">
            <div class="navigation__menu__content">
                <div class="navigation__menu__main">
                    <navlink
                        v-for="(link, index) in mainNavigation"
                        :key="index"
                        :type="mapType(link.__typename)"
                        :target="mapTarget(link.targetBlank)"
                        :url="mapURI(link)"
                        :icon="link.icon"
                        class="navigation__menu__mainlink"
                        @click.native="closeMenu"
                    >
                        {{ link.titel }}
                    </navlink>
                </div>

                <template v-if="isDoen || isBglf">
                    <nuxt-link :to="localePath(applyLink)" class="navigation__menu__button button button--tertiary" @click.native="closeMenu">
                        <template v-if="isDoen">{{ $t('navigation.links.application_doen') }}</template>
                        <template v-else>{{ $t('navigation.links.application') }}</template>
                    </nuxt-link>
                    <div class="navigation__menu__extralink">
                        <nuxt-link :to="localePath({ name: 'mijn' })" @click.native="closeMenu">
                            <span v-if="isDoen">{{ $t('navigation.links.account_doen') }}</span>
                            <span v-else>{{ $t('navigation.links.account') }}</span>
                            <icon><icon-user /></icon>
                        </nuxt-link>
                    </div>
                </template>

                <template v-if="isParticipaties">
                    <div class="navigation__menu__extralink">
                        <a :href="doenLink" class="navigation__menu__extralink"><span>{{ $t('navigation.links.doen') }}</span> <icon><icon-arrow-right-large /></icon></a>
                    </div>
                </template>

                <template v-if="isDoen">
                    <div class="navigation__menu__extralink">
                        <a :href="participatiesLink" class="navigation__menu__extralink"><span>{{ $t('navigation.links.participation') }}</span> <icon><icon-arrow-right-large /></icon></a>
                    </div>
                </template>

                <template v-if="isParticipaties || isDoen">
                    <div v-if="localisations" class="navigation__menu__extralink">
                        <nuxt-link
                            v-for="(localisation, key) in localisations"
                            :key="key"
                            :to="localePath(localisation.uri, localisation.language)"
                            class="navigation__menu__extralink"
                            @click.native="closeMenu"
                        >
                            <span>{{ $t('language.switch') }}</span> <icon><icon-flag /></icon>
                        </nuxt-link>
                    </div>
                    <div v-else-if="otherLocalePath" class="navigation__menu__extralink">
                        <nuxt-link :to="otherLocalePath" class="navigation__menu__extralink" @click.native="closeMenu"><span>{{ $t('language.switch') }}</span> <icon><icon-flag /></icon></nuxt-link>
                    </div>
                </template>

                <div v-if="isDoen" class="navigation__menu__socials">
                    <a href="https://www.facebook.com/stichtingdoen/?fref=ts" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-facebook /></icon></a>
                    <a href="https://twitter.com/StichtingDOEN" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-twitter /></icon></a>
                    <a href="https://www.linkedin.com/company/stichting-doen" class="navigation__menu__socials__link navigation__menu__socials__linkedin" target="_blank" rel="noreferrer noopener"><icon><icon-linkedin /></icon></a>
                    <a href="https://www.youtube.com/user/StichtingDOEN" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-youtube /></icon></a>
                    <a href="https://www.instagram.com/stichtingdoen/" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-instagram /></icon></a>
                </div>
                <div v-else-if="isBglf" class="navigation__menu__socials">
                    <a href="https://www.facebook.com/bankgiroloterijfonds" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-facebook /></icon></a>
                </div>
                <div v-else-if="isParticipaties" class="navigation__menu__socials">
                    <a href="https://www.linkedin.com/showcase/doen-participaties/" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-linkedin /></icon></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
'use strict';

import { mapActions, mapGetters, mapState } from 'vuex';
import { throttle } from 'lodash';
import { mixin as Clickaway } from 'vue-clickaway';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';
import NavigationLogo from '~/patterns/atoms/logo/logo.vue';
import Icon from '~/patterns/atoms/icon/icon.vue';
import IconUser from '~/patterns/atoms/_icons/user.vue';
import IconFlag from '~/patterns/atoms/_icons/flag.vue';
import IconArrowRightLarge from '~/patterns/atoms/_icons/arrow-large-right.vue';
import IconSearch from '~/patterns/atoms/_icons/search.vue';
import IconFacebook from '~/patterns/atoms/_icons/facebook.vue';
import IconTwitter from '~/patterns/atoms/_icons/twitter.vue';
import IconLinkedin from '~/patterns/atoms/_icons/linkedin.vue';
import IconYoutube from '~/patterns/atoms/_icons/youtube.vue';
import IconInstagram from '~/patterns/atoms/_icons/instagram';
import Hamburger from '~/patterns/atoms/hamburger/hamburger.vue';

export default {
    components: {
        NavigationLogo,
        Icon,
        IconUser,
        IconFlag,
        IconArrowRightLarge,
        IconSearch,
        Hamburger,
        IconFacebook,
        IconTwitter,
        IconLinkedin,
        IconYoutube,
        IconInstagram,
        Navlink
    },
    mixins: [
        Navigation,
        Clickaway
    ],
    props: {
        applyLink: {
            type: String,
            default: '/aanvraag-doen',
            required: false
        }
    },
    data() {
        return {
            scrolling: false,
            minimalScrollForFold: 50,
            menuOpen: false
        };
    },
    async fetch() {
        await this.getMain();
    },
    computed: {
        navigationClasses() {
            let classes = '';

            if (this.menuOpen) {
                classes += 'navigation--open';
            }
            if (this.scrolling) {
                classes += ' navigation--scrolling';
            }

            return classes;
        },
        otherLocalePath() {
            return this.switchLocalePath(this.otherLocale);
        },
        doenLink() {
            return this.locale === 'en' ? 'https://doen.nl/en' : 'https://doen.nl';
        },
        participatiesLink() {
            return this.locale === 'en' ? 'https://participaties.doen.nl/en' : 'https://participaties.doen.nl';
        },
        ...mapGetters('navigation', {
            mainNavigation: 'main',
            otherLocale: 'otherLocale'
        }),
        ...mapGetters('sites', ['theme', 'isDoen', 'isBglf', 'isParticipaties', 'activeSite']),
        ...mapState('i18n', ['locale']),
        ...mapGetters('l10n', ['localisations'])
    },
    watch: {
        activeSite() {
            this.getMain();
        }
    },
    mounted() {
        window.addEventListener(
            'scroll',
            throttle(this.handleScroll, 100, {
                leading: true
            })
        );
    },
    destroyed() {
        window.removeEventListener(
            'scroll',
            this.handleScroll
        );
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        closeMenu() {
            this.menuOpen = false;
        },
        handleScroll() {
            const currentScrollPosition = window.pageYOffset || window.scrollY;

            if (currentScrollPosition > this.minimalScrollForFold) {
                this.scrolling = true;
            } else {
                this.scrolling = false;
            }
        },
        ...mapActions('navigation', ['getMain'])
    }
};
</script>

<style lang="less" src="./navigation.less" />
