export default {
    self: {
        url: 'https://doen.ganymede.digitalnatives.nl'
    },
    api: {
        endpoint: 'https://api.doen.acc.ganymede.digitalnatives.nl/graphql',
        token: 'Bearer QkMpJUM2PBoacsE1M52BhZObgGRapdx1'
    },
    googleTagManagerId: '',
    sites: {
        'doen.ganymede.digitalnatives.nl': {
            nl: 2,
            en: 5
        },
        'bglf.doen.ganymede.digitalnatives.nl': {
            nl: 3
        },
        'vriendenloterijfonds.doen.ganymede.digitalnatives.nl': {
            nl: 3
        },
        'participaties.doen.ganymede.digitalnatives.nl': {
            nl: 4,
            en: 7
        }
    },
    defaults: {
        siteId: 2,
        locale: 'nl',
        title: 'Stichting DOEN',
        meta: {
            description: 'Stichting DOEN'
        }
    },
    siteConfig: {
        2: {
            title: '<%= title %> | Stichting DOEN'
        },
        5: {
            title: '<%= title %> | Stichting DOEN'
        },
        3: {
            title: '<%= title %> | VriendenLoterij Fonds'
        },
        4: {
            title: '<%= title %> | DOEN Participaties'
        },
        7: {
            title: '<%= title %> | DOEN Participaties'
        }
    },
    themes: {
        2: 'doen',
        5: 'doen',
        3: 'bglf',
        4: 'participaties',
        7: 'participaties'
    },
    gtm: {
        2: 'GTM-MNX2TH',
        5: 'GTM-MNX2TH',
        3: 'GTM-TRFG39P',
        4: 'GTM-57BBHTS',
        7: 'GTM-57BBHTS'
    },
    algolia: {
        appId: 'AMR9SFB6YU',
        apiKey: '9a2ec254c4e62baffc73fe1389e31624',
        indexes: {
            2: 'DOEN_search',
            5: 'DOEN EN_search',
            3: 'VriendenLoterij Fonds_search',
            4: 'DOEN Participaties_search',
            7: 'DOEN Participaties EN_search'
        },
        projectIndexes: {
            2: 'DoenProjects',
            5: 'DoenEnProjects',
            3: 'BglfProjects'
        },
        portfolioIndexes: {
            4: 'DoenParticipatiesPortfolio',
            7: 'DoenParticipatiesEnPortfolio'
        }
    },
    quickscan: {
        enabled: [2, 5, 3]
    },
    mijnomgeving: {
        enabled: [2, 5, 3]
    },
    projects: {
        enabled: [2, 5, 3]
    },
    portfolio: {
        enabled: [4, 7]
    },
    maps: {
        token: 'hCaZRjJggMSxNhNqKQCyXhgzcsEELxXXTcLu0RHrKIiq65xarzs7BXyPM225ZbRc'
    }
};
