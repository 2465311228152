export default {
    self: {
        url: 'localhost:3000'
    },
    api: {
        assets: 'http://api.doen.test/',
        endpoint: 'http://api.doen.test/graphql',
        token: 'Bearer -x97ZRnADxIPTzJsDzfYBjK6awVltRz8'
    },
    sites: {
        'localhost:3000': {
            nl: 2,
            en: 5
        },
        'doen.test:3000': {
            nl: 2,
            en: 5
        },
        'bglf.doen.test:3000': {
            nl: 3
        },
        'vriendenloterijfonds.doen.test:3000': {
            nl: 3
        },
        'participaties.doen.test:3000': {
            nl: 4,
            en: 6
        }
    },
    defaults: {
        siteId: 2,
        locale: 'nl',
        title: 'Stichting DOEN',
        meta: {
            description: 'Stichting DOEN'
        }
    },
    siteConfig: {
        2: {
            title: '<%= title %> | Stichting DOEN'
        },
        5: {
            title: '<%= title %> | Stichting DOEN'
        },
        3: {
            title: '<%= title %> | VriendenLoterij Fonds'
        },
        4: {
            title: '<%= title %> | DOEN Participaties'
        },
        6: {
            title: '<%= title %> | DOEN Participaties'
        }
    },
    themes: {
        2: 'doen',
        5: 'doen',
        3: 'bglf',
        4: 'participaties',
        6: 'participaties'
    },
    gtm: {
        2: 'GTM-XXXX',
        5: 'GTM-YYYY',
        3: 'GTM-ZZZZ',
        4: 'GTM-WWWW',
        6: 'GTM-WWWW'
    },
    algolia: {
        appId: 'AMR9SFB6YU',
        apiKey: '9a2ec254c4e62baffc73fe1389e31624',
        indexes: {
            2: 'DOEN_search',
            5: 'DOEN EN_search',
            3: 'VriendenLoterij Fonds_search',
            4: 'DOEN Participaties_search',
            6: 'DOEN Participaties EN_search'
        },
        projectIndexes: {
            2: 'DoenProjects',
            5: 'DoenEnProjects',
            3: 'BglfProjects'
        },
        portfolioIndexes: {
            4: 'DoenParticipatiesPortfolio',
            6: 'DoenParticipatiesEnPortfolio'
        }
    },
    quickscan: {
        enabled: [2, 5, 3]
    },
    mijnomgeving: {
        enabled: [2, 5, 3]
    },
    projects: {
        enabled: [2, 5, 3]
    },
    portfolio: {
        enabled: [4, 6]
    },
    maps: {
        token: 'hCaZRjJggMSxNhNqKQCyXhgzcsEELxXXTcLu0RHrKIiq65xarzs7BXyPM225ZbRc'
    }
};
