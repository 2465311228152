<script>
import { Layout } from '@digitalnatives/styleguide';
import { mapGetters } from 'vuex';
import StyleguideRoutes from '~/styleguide/config/routes.js';

export default {
    extends: Layout,

    data() {
        return {
            routes: StyleguideRoutes
        };
    },

    head() {
        return {
            bodyAttrs: {
                'data-theme': this.siteTheme
            }
        };
    },

    computed: {
        ...mapGetters('sites', {
            siteTheme: 'theme'
        })
    }
};
</script>
