<template>
    <footer class="footer">
        <nav class="footer__main">
            <item-grid
                class="item-grid--mb"
                data-columns-xs="1"
                data-columns-sm="1"
                data-columns-md="3"
                data-columns-lg="3"
                data-columns-xl="3"
            >
                <div class="item-grid__item">
                    <h4 class="footer__title">{{ leftHeader }}</h4>
                    <div v-for="(link, index) in leftLinks" :key="index">
                        <navlink
                            :type="mapType(link.__typename)"
                            :target="mapTarget(link.targetBlank)"
                            :url="mapURI(link)"
                            :icon="link.icon"
                            class="footer__main__link"
                        >
                            {{ link.titel }}
                        </navlink>
                    </div>
                </div>
                <div class="item-grid__item">
                    <h4 class="footer__title">{{ middleHeader }}</h4>
                    <div v-for="(link, index) in middleLinks" :key="index">
                        <navlink
                            :type="mapType(link.__typename)"
                            :target="mapTarget(link.targetBlank)"
                            :url="mapURI(link)"
                            :icon="link.icon"
                            class="footer__main__link"
                        >
                            {{ link.titel }}
                        </navlink>
                    </div>
                </div>
                <div class="item-grid__item">
                    <h4 class="footer__title">{{ rightHeader }}</h4>
                    <div v-for="(link, index) in rightLinks" :key="index">
                        <navlink
                            :type="mapType(link.__typename)"
                            :target="mapTarget(link.targetBlank)"
                            :url="mapURI(link)"
                            :icon="link.icon"
                            class="footer__main__link"
                        >
                            {{ link.titel }}
                        </navlink>
                    </div>
                </div>
            </item-grid>
        </nav>

        <div class="footer__extra">
            <item-grid
                v-if="isBglf"
                data-columns-xs="1"
                data-columns-sm="1"
                data-columns-md="1"
                data-columns-lg="3"
                data-columns-xl="3"
            >
                <i18n path="footer.bglf" tag="p" class="item-grid__item-double">
                    <template v-slot:doen>
                        <a href="https://www.doen.nl" target="_blank">{{ $t('footer.label_doen') }}</a>
                    </template>
                    <template v-slot:vriendenloterij>
                        <a href="https://www.vriendenloterij.nl" target="_blank">{{ $t('footer.label_vriendenloterij') }}</a>
                    </template>
                </i18n>
                <div v-if="locale === 'nl'" class="item-grid__item-single">
                    <nuxt-link :to="{ path: '/over-het-fonds' }" class="footer__link link--clean">
                        <img class="footer__extra__doen" src="~/assets/img/logos/logo-doen-original.svg" alt="Stichting DOEN">
                    </nuxt-link>
                </div>
            </item-grid>
            <div v-else-if="isParticipaties">
                {{ $t('footer.participaties') }}
            </div>
            <item-grid
                v-else-if="isDoen"
                data-columns-xs="1"
                data-columns-sm="1"
                data-columns-md="1"
                data-columns-lg="2"
                data-columns-xl="2"
            >
                <div class="item-grid__item-single">
                    <nuxt-link :to="locale === 'nl' ? '/over-ons/goede-doelen-loterijen' : '/en/about-us/charity-lotteries'" class="link--clean">
                        <span class="footer__extra__donors-text">
                            {{ $t('footer.doen_donors') }}
                        </span>
                        <div class="footer__extra__donors">
                            <img src="~/assets/img/logos/logo-npl-box.png" alt="De Nationale Postcode Loterij" width="642" height="424" loading="lazy" decoding="async" fetchpriority="low">
                            <img src="~/assets/img/logos/logo-vl-box.png" alt="De Vriendenloterij" width="642" height="420" loading="lazy" decoding="async" fetchpriority="low">
                        </div>
                    </nuxt-link>
                </div>
                <div v-if="locale === 'nl'" class="item-grid__item-single footer__icons">
                    <nuxt-link :to="{ path: '/fin-normen-goed-bestuur' }" class="footer__link link--clean">
                        <img class="footer__extra__fin" src="~/assets/img/logos/logo-fin-goedbestuur.png" alt="">
                    </nuxt-link>
                    <nuxt-link :to="{ path: '/over-ons/doen-is-een-cbf-erkend-goed-doel' }" class="footer__link link--clean">
                        <span class="footer__extra__cbf-text">{{ $t('footer.doen_cbf') }}</span>
                        <img class="footer__extra__cbf" src="~/assets/img/logos/logo-cbf.png" alt="">
                    </nuxt-link>
                </div>
            </item-grid>
        </div>

        <nav class="footer__bottom">
            <div class="footer__bottom__container">
                <div v-for="(link, index) in bottomLinks" :key="index" class="footer__bottom__link">
                    <navlink
                        :type="mapType(link.__typename)"
                        :target="mapTarget(link.targetBlank)"
                        :url="mapURI(link)"
                        :icon="link.icon"
                    >
                        {{ link.titel }}
                    </navlink>
                </div>
            </div>
        </nav>
    </footer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { get } from 'lodash';

import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';

export default {
    components: {
        Navlink,
        ItemGrid
    },
    mixins: [
        Navigation
    ],
    async fetch() {
        await this.getFooter();
    },
    computed: {
        leftHeader() {
            return get(this, 'footer.left.header', '');
        },
        leftLinks() {
            return get(this, 'footer.left.links', []);
        },
        middleHeader() {
            return get(this, 'footer.middle.header', '');
        },
        middleLinks() {
            return get(this, 'footer.middle.links', []);
        },
        rightHeader() {
            return get(this, 'footer.right.header', '');
        },
        rightLinks() {
            return get(this, 'footer.right.links', []);
        },
        bottomLinks() {
            return get(this, 'footer.bottom.links', []);
        },
        ...mapGetters('navigation', ['footer']),
        ...mapGetters('sites', ['theme', 'isDoen', 'isBglf', 'isParticipaties', 'activeSite']),
        ...mapState('i18n', ['locale'])
    },
    watch: {
        activeSite() {
            this.getFooter();
        }
    },
    methods: {
        ...mapActions('navigation', ['getFooter'])
    }
};
</script>

<style src="./footer.less" lang="less" />
