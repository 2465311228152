import Vue from 'vue';

import { latLng, latLngBounds, point, Icon } from 'leaflet';
import { LIcon, LMap, LMarker, LTileLayer, LControlScale } from 'vue2-leaflet';

const VueLeaflet = {
    install(vm) {
        setIconDefault();

        vm.component('LMap', LMap);
        vm.component('LMarker', LMarker);
        vm.component('LTileLayer', LTileLayer);
        vm.component('LIcon', LIcon);
        vm.component('LControlScale', LControlScale);

        vm.prototype.$latLng = latLng;
        vm.prototype.$latLngBounds = latLngBounds;
        vm.prototype.$point = point;
    }
};

function setIconDefault() {
    delete Icon.Default.prototype._getIconUrl;

    Icon.prototype._createImg = (src, el) => {
        const img = document.createElement('img');

        el = el || document.createElement('figure');

        img.src = src;

        el.innerHTML = '';
        el.appendChild(img);

        return el;
    };

    Icon.Default.mergeOptions({
        iconRetinaUrl: '/img/markers/default.svg',
        iconUrl: '/img/markers/default.svg',
        shadowUrl: '/img/markers/shadow.svg',
        iconSize: [38, 46],
        shadowSize: [56, 65]
    });
}

Vue.use(VueLeaflet);

export default VueLeaflet;
