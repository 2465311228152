export default {
    self: {
        url: 'https://new.doen.nl'
    },
    api: {
        endpoint: 'https://cms.doen.nl/graphql',
        token: 'Bearer QkMpJUM2PBoacsE1M52BhZObgGRapdx1'
    },
    sites: {
        'new.doen.nl': {
            nl: 2,
            en: 5
        },
        'bglf.doen.nl': {
            nl: 3
        },
        'www.doen.nl': {
            nl: 2,
            en: 5
        },
        'bankgiroloterijfonds.doen.nl': {
            nl: 3
        },
        'vriendenloterijfonds.doen.nl': {
            nl: 3
        },
        'participaties.doen.nl': {
            nl: 4,
            en: 7
        }
    },
    defaults: {
        siteId: 2,
        locale: 'nl',
        title: 'Stichting DOEN',
        meta: {
            description: 'Stichting DOEN'
        }
    },
    siteConfig: {
        2: {
            title: '<%= title %> | Stichting DOEN'
        },
        5: {
            title: '<%= title %> | Stichting DOEN'
        },
        3: {
            title: '<%= title %> | VriendenLoterij Fonds'
        },
        4: {
            title: '<%= title %> | DOEN Participaties'
        },
        7: {
            title: '<%= title %> | DOEN Participaties'
        }
    },
    themes: {
        2: 'doen',
        5: 'doen',
        3: 'bglf',
        4: 'participaties',
        7: 'participaties'
    },
    gtm: {
        2: 'GTM-MNX2TH',
        5: 'GTM-MNX2TH',
        3: 'GTM-TRFG39P',
        4: 'GTM-57BBHTS',
        7: 'GTM-57BBHTS'
    },
    algolia: {
        appId: '90GIC7B0X8',
        apiKey: '02a5421a198d10a6c3e7389f84153b39',
        indexes: {
            2: 'DOEN_search',
            5: 'DOEN EN_search',
            3: 'VriendenLoterij Fonds_search',
            4: 'DOEN Participaties_search',
            7: 'DOEN Participaties_search'
        },
        projectIndexes: {
            2: 'DoenProjects',
            5: 'DoenEnProjects',
            3: 'BglfProjects'
        },
        portfolioIndexes: {
            4: 'DoenParticipatiesPortfolio',
            7: 'DoenParticipatiesEnPortfolio'
        }
    },
    quickscan: {
        enabled: [2, 5, 3]
    },
    mijnomgeving: {
        enabled: [2, 5, 3]
    },
    projects: {
        enabled: [2, 5, 3]
    },
    portfolio: {
        enabled: [4, 7]
    },
    maps: {
        token: 'hCaZRjJggMSxNhNqKQCyXhgzcsEELxXXTcLu0RHrKIiq65xarzs7BXyPM225ZbRc'
    }
};
